<template>
  <div class="position-relative">

    <b-card-title class="mb-1">
      {{ $t('auth.ready.store_type') }}
    </b-card-title>
    <b-tabs
      v-model="storeType"
      small
    >
      <b-tab
        v-for="type in storeTypeOptions"
        :key="type"
      >
        <template #title>
          <feather-icon
            v-if="storeTypeName === type"
            icon="CheckIcon"
          />
          {{ $t(`auth.ready.store_type_options.${type}.title`) }}
        </template>
        <b-card-text>
          <img
            :src="images[type]"
            alt=""
            width="100%"
          >
          {{ $t(`auth.ready.store_type_options.${type}.description`) }}
        </b-card-text>
      </b-tab>
    </b-tabs>
    <!-- submit button -->
    <b-button
      v-if="storeTypeName === 'affiliate'"
      variant="primary"
      block
      type="button"
      class="mt-2"
      @click="$emit('save', {'store_type': 'affiliate'})"
    >
      {{ $t('Save') }}
    </b-button>
    <b-button
      v-else
      variant="primary"
      block
      type="button"
      class="mt-2"
      @click="$emit('next')"
    >
      {{ $t('Next') }}
    </b-button>
  </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {
  platformName, platformUrl, trackEvent,
} from '@core/utils/utils'
import {
  BButton, BCardText, BCardTitle, BTab, BTabs,
} from 'bootstrap-vue'
import { ref } from 'vue'

export default {
  components: {
    BCardText,
    BCardTitle,
    BTabs,
    BTab,
    BButton,
    // BSV
    // validations
  },
  mixins: [togglePasswordVisibility],
  emits: ['next', 'save', 'input'],
  data() {
    return {
      images: {
        retail: require('@/assets/images/illustration/retail_green.svg'),
        drop_shipping: require('@/assets/images/illustration/dropshipping_blue.svg'),
        affiliate: require('@/assets/images/illustration/affiliate_purple.svg'),
      },
      storeTypeOptions: ['retail', 'drop_shipping', 'affiliate'],
      // storeType: 'retail',
    }
  },
  computed: {
    storeTypeName() {
      if (!this.storeType) {
        return 'retail'
      }

      const val = this.storeTypeOptions[this.storeType]
      this.$emit('input', val)
      return val
    },
  },
  mounted() {
    trackEvent('InitiateReadiness')
  },
  methods: {
    platformUrl,
    platformName,
  },
  setup() {
    const storeType = ref(0)
    return {
      storeType,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.vs__dropdown-menu {
  max-height: 200px !important;
}

.ready-submit-btn {
  bottom: 22px;
  z-index: 99;
}
</style>
