<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-overlay
            :show="isLoading"
            rounded="sm"
            variant="secondary"
            opacity="0.75"
        >
          <b-link
              class="brand-logo"
              :href="platformUrl()"
          >
            <platform-logo />
            <h2 class="brand-text text-primary ml-1">
              {{ platformName() }}
            </h2>
          </b-link>

          <p v-if="alertMessage">
            {{ alertMessage }}
          </p>
          <!-- form -->
          <validation-observer v-else ref="registerForm">
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="validationForm"
            >
              <ready-step1
                  v-if="step === 0"
                  @next="step=1"
                  @save="performSubmit"
                  @input="v => storeData.store_type = v"
              />

              <template v-else>

                <b-card-title class="mb-1">
                  {{ $t('auth.ready.title') }}
                </b-card-title>
                <b-card-text class="mb-2">
                  {{ $t('auth.ready.subtitle') }}
                </b-card-text>
                <!-- Store Name -->
                <i-input
                    id="store_name"
                    ref="store_name"
                    v-model="storeData.name"
                    type="text"
                    title="modules.settings.inputs.store_name"
                    placeholder="modules.settings.inputs.store_name:placeholder"
                    :translatable="true"
                    :errors="{'ar': validationErrorsArray('name.ar'), 'en': validationErrorsArray('name.en')}"
                    maxlength="100"
                    :validation-rules="['required', 'min:2']"
                />

                <!-- Store Industry -->
                <i-input
                    id="store_industry"
                    v-model="storeData.industry_code"
                    :errors="validationErrorsArray('industry_code')"
                    type="select"
                    title="modules.settings.inputs.store_industry"
                    :options="industryOptions"
                    :reduce="option => option.value"
                    required
                />

                <i-input
                    id="store_country"
                    v-model="storeData.country_code"
                    :errors="validationErrorsArray('country_code')"
                    type="select"
                    title="modules.settings.inputs.store_country"
                    placeholder="modules.settings.inputs.store_country:placeholder"
                    :options="countryOptions"
                    :reduce="option => option.value"
                    :clearable="true"
                    required
                />

                <i-input
                    id="store_currency"
                    v-model="storeData.currency_code"
                    :errors="validationErrorsArray('currency_code')"
                    type="select"
                    title="modules.settings.inputs.store_currency"
                    placeholder="modules.settings.inputs.store_currency:placeholder"
                    :options="currencyOptions"
                    :reduce="option => option.value"
                    :clearable="true"
                    required
                />

                <!-- submit button -->
                <b-button
                    variant="primary"
                    block
                    type="submit"

                >
                  {{ $t('Save') }}
                </b-button>
                <b-button
                    variant="light"
                    block
                    type="button"
                    @click="step=0"

                >
                  {{ $t('Back') }}
                </b-button>
              </template>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-card>


      <b-card-text class="text-center mt-2">
        <b-link @click="logout">
          <span>{{ $t('Logout') }}</span>
        </b-link>
      </b-card-text>
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BCol,
  BInputGroupText,
  BInputGroupPrepend, BOverlay, BBadge,
} from 'bootstrap-vue'
import PlatformLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {platformName, platformUrl, toast, trackEvent} from '@core/utils/utils'
import {ref} from "vue";
import axios from "@/libs/axios";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import useModelUpdate from "@/views/models/common/useModelUpdate";
import RadioOption from "@core/components/radio-option/RadioOption.vue";
import ReadyStep1 from "@/views/pages/authentication/Ready-Step1.vue";

export default {
  components: {
    ReadyStep1,
    BBadge,
    RadioOption,
    BOverlay,
    BInputGroupPrepend,
    BInputGroupText,
    BCol,
    PlatformLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      step: 0,
      regEmail: '',
      username: '',
      password: '',
      status: '',
      storeData: {
        name: {
          en: null,
          ar: null,
        },
        industry_code: null,
        country_code: null,
        currency_code: null,
        store_type: null,
      },
      remoteErrors: {},
      isLoading: false,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    trackEvent('InitiateReadiness')
  },
  methods: {
    platformUrl,
    platformName,
    validationError(attr) {
      if (attr === 'name') {
        return this.validationError('name.ar') || this.validationError('name.en')
      }
      return this.remoteErrors[`${attr}`]?.[0] || null
    },
    validationErrorsArray(attr) {
      const error = this.validationError(attr)
      return error ? [error] : []
    },
    performSubmit(payload) {
      this.isLoading = true
      axios
          .put(`dashboard/v1/ready`, payload)
          .then(response => {
            localStorage.setItem('store.currency', this.storeData.currency_code)
            // router.push({ name: 'home' })    // We need to reload the page to use the currency
            window.location.replace("/");
            trackEvent('CompleteReadiness', this.storeData)
          })
          .catch(err => {
            if (err?.response?.status === 422) {
              const responseData = err.response.data

              this.remoteErrors = responseData.errors
              toast('warning', this.$t('message.fill_required_fields'))
              return
            }

            if ((err?.response?.status === 400) && (err?.response?.data?.message)) {
              toast('danger', err?.response?.data?.message)
            } else {
              toast('danger', this.$t('message.operation_failed'))
            }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    validationForm() {
      this.$refs.registerForm.validateWithInfo().then(({ isValid, errors, fields }) => {
        if (isValid) {
          this.performSubmit(this.storeData)
        } else {
          toast('warning', this.$t('message.fill_required_fields'))

          const failedFields = Object.keys(fields).filter(key => fields[key].failed)
          const { focusOnTabsOfErrors } = useModelUpdate(null, null, null ,null)
          focusOnTabsOfErrors(failedFields, this.$refs)
        }
      })
    },
    logout() {
      useJwt.logoutAndRevokeToken().then(() => {
        useJwt.redirectToLoginPage()
      })
    },
  },
  setup() {
    const countryOptions = ref([])
    const currencyOptions = ref([])
    const industryOptions = ref([])
    const alertMessage = ref(null)

    axios
        .get(`dashboard/v1/ready-options`)
        .then(response => {
          if (response.data?.is_ready) {
            router.push({ name: 'home' })
            return
          }
          if (response.data?.is_coming_soon) {
            alertMessage.value = response.data.message
            return
          }
          countryOptions.value = response.data.countries
          currencyOptions.value = response.data.currencies
          industryOptions.value = response.data.industries
        })

    return {
      countryOptions,
      currencyOptions,
      industryOptions,
      alertMessage,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.vs__dropdown-menu {
  max-height: 200px !important;
}
</style>
